import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "../../assets/images/defimg.jpeg";
import InputNumber from "../Inputnumber";
import InputCheckbox from "../Inputcheckbox";
import Input from "../Input";
import Dropdown from "../Dropdown";
import SingleImageSelect from "../Media/SingleImageSelect";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  MediaList,
  TaxDropDown,
  productView,
} from "../../service/apiService";
import { useLocation, useNavigate } from "react-router-dom";

const ProductViewForm = () => {
  const location = useLocation();
  const { product_id } = location.state || {};
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.gentlemenz.in/api/files/";
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productData, setProductData] = useState({});
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;


  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      CategoryDropdownList();
      BrandDropDownList();
      TaxDropDownList();
      DiscountDropDownList();
      productList();
    }
  }, [token]);
  useEffect(() => {
    setProductFormData({
      ...productformData,
      ...productData,
    });
  }, [productData]);
  const [productformData, setProductFormData] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: "",
    unit: "",
    weight: "",
    returning_date: "",
    is_active: 1,
  });


  const handleChange = (e) => {
    const { id, value } = e.target;

    let updatedFormData = {
      ...productformData,
      [id]: value,
    };

    if (id === "product_name") {
      updatedFormData = {
        ...updatedFormData,
        slug: generateSlug(value),
      };
    }


    setProductFormData(updatedFormData);
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setProductFormData({
      ...productformData,
      is_active: activeValue,
    });
  };
  const productList = async () => {
    const response = await productView(token, product_id);
    setProductData(response.data);
  };

  const CategoryDropdownList = async () => {
    const CategoriesDropDownList = await CategoriesDropDown(token);
    setCategoryList(CategoriesDropDownList.data);
  };
  const BrandDropDownList = async () => {
    const BrandDropDownList = await BrandDropDown(token);
    setBrandList(BrandDropDownList.data);
  };
  const TaxDropDownList = async () => {
    const TaxDropDownList = await TaxDropDown(token);
    setTaxList(TaxDropDownList.data);
  };
  const DiscountDropDownList = async () => {
    const DiscountDropDownList = await DiscountDropDown(token);
    setDiscountList(DiscountDropDownList.data);
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter((i) => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      closeModal();
    }
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };
  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };



  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <form className="row">
            <div className="col-md-4">
              <label htmlFor="inputEmail5" className="form-label">
                Product Name
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="product_name"
                value={productformData.product_name}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputState" className="form-label">
                Brand
              </label>
              <select
                id="brand"
                className="form-select"
                onChange={handleChange}
                disabled={true}
              >
                {!brandList ? (
                  <option value="">No Data...</option>
                ) : brandList.length === 0 ? (
                  <option value="">No brand found</option>
                ) : (
                  <>
                    <option value="">Select brand</option>
                    {brandList.map((brand) => (
                      <option
                        key={brand.id}
                        value={brand.id}
                        selected={productformData.brand_id === brand.id}
                      >
                        {brand.brand_name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Description
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="description"
                value={productformData.description}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputState" className="form-label">
                Category
              </label>
              <select
                id="category"
                className="form-select"
                // value={productformData.category}
                onChange={handleChange}
                disabled={true}
              >
                {!categoryList ? (
                  <option value="">No Data...</option>
                ) : categoryList.length === 0 ? (
                  <option value="">No category found</option>
                ) : (
                  <>
                    <option value="">Select Category</option>
                    {categoryList.map((category) => (
                      <option
                        key={category.id}
                        value={category.id}
                        selected={productformData.category_id === category.id}
                      >
                        {category.category_name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Specification
              </label>
              <Input type="text" placeholder="Enter specification" className="form-control" id="specification" value={productformData.specification} onChange={handleChange} disabled={true} />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Slug
              </label>
              <input
                type="text"
                placeholder="Type here"
                name="slug"
                className="form-control"
                id="slug"
                value={productformData.slug}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Sku
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="sku"
                value={productformData.sku}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Is have Variant
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="is_have_variant"
                value={productformData.is_have_variant}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Quantity
              </label>
              <Input
                type="text"
                placeholder="Type here"
                name="quantity"
                className="form-control"
                id="quantity"
                value={productformData.quantity}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Unit
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="unit_id"
                value={productformData.unit_id}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Minimum Stock Warning
              </label>
              <Input
                type="number"
                placeholder="Type here"
                className="form-control"
                id="minimum_stock_warning"
                value={productformData.minimum_stock_warning}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Weight
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="weight"
                value={productformData.weight}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Price
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="original_price"
                value={productformData.original_price}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword5" className="form-label">
                Returning Date
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="returning_date"
                value={productformData.returning_date}
                onChange={handleChange}
                disabled={true}
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputState" className="form-label">
                Discount
              </label>
              <select
                id="discount"
                className="form-select"
                onChange={handleChange}
                disabled={true}
              >
                {!discountList ? (
                  <option value="">No Data...</option>
                ) : discountList.length === 0 ? (
                  <option value="">No Discount found</option>
                ) : (
                  <>
                    <option value="">Select Discount</option>
                    {discountList.map(
                      (discount) => (

                        (
                          <option
                            key={discount.id}
                            value={discount.id}
                            selected={
                              productformData.discount_id === discount.id
                            }
                          >
                            {discount.name.toUpperCase()}
                          </option>
                        )
                      )
                    )}
                  </>
                )}
              </select>
            </div>
            <div className="col-md-4">
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Status
              </label>
              {/* <div className="form-check form-switch"> */}
              <Input
                className="form-check-input"
                type="text"
                id="is_active"
                value={productformData.is_active === 1 ? 'active' : 'inactive'}
                disabled={true}
                onChange={handleChange}
              />
            </div>
            {/* </div> */}
            <div className="col-md-4">
              <label htmlFor="inputState" className="form-label">
                Tax
              </label>
              <select id="tax" className="form-select" disabled={true} onChange={handleChange}>
                {!taxList ? (
                  <option value="">No Data...</option>
                ) : taxList.length === 0 ? (
                  <option value="">No tax found</option>
                ) : (
                  <>
                    <option value="">Select Tax</option>
                    {taxList.map((tax) => (
                      <option
                        key={tax.id}
                        value={tax.id}
                        selected={productformData.tax_id === tax.id}
                      >
                        {tax.label.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="row">
              <div className="col-md-4">
              </div>
              <label>Thumbnail Image</label>
              <br />
              {/* {previewImage ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      width: "200px",
                      height: "200px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleDeletePreview}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ) : (
                <img src={UploadImage} alt="Alt" />
              )} */}
              <br></br>
              {/* <div className="d-flex justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openModal}
                >
                  Select Image
                </button>
              </div> */}
              <img
                src={`${productformData.thumnail_image}`}
                alt="Product Image Preview"
              />
            </div>
            <hr></hr>

          </form>
        </div>
      </div>
      <SingleImageSelect
        isOpen={modalIsOpen}
        handleClose={closeModal}
        handleImageClick={handleImageClick}
        selectedImages={selectedImages}
        imageUrl={imageUrl}
      />
    </div>
  );
};

export default ProductViewForm;
