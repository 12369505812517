import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faArrowRight, faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";




const SellerList = () => {
    const navigate = useNavigate();

    const [Seller, setSeller] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedSellerId, setSelectedSellerId] = useState(null);
    const [sellerformData, setSellerFormData] = useState({
        seller_name: '',
        address: '',
        email: '',
        phone_no: '',
        is_active: 0,
    });

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(Seller.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentSeller = Seller.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await fetch('https://folcon.gentlemenz.in/admin/all-sellers', config);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();

            let SellerData = [];

            if (Array.isArray(responseData)) {
                SellerData = responseData;
            } else if (Array.isArray(responseData.data)) {
                SellerData = responseData.data;
            } else {
                throw new Error('Data is not in the expected format');
            }
            setSeller(SellerData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = async (SellerId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.gentlemenz.in/admin/get-seller/${SellerId}`, config);
            const responseData = response.data;

            setSellerFormData({
                seller_name: responseData.data.name,
                address: responseData.data.address,
                email: responseData.data.email,
                phone_no: responseData.data.phone_no,
                is_active: responseData.data.is_active,
            });
            setSelectedSellerId(SellerId);
            openModal();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!sellerformData.seller_name || !sellerformData.address) {
                toast.error('Please Fill The Form Data');
                return;
            }

            const updatePayload = {
                ...sellerformData,
                seller_id: selectedSellerId,
                name: sellerformData.seller_name,
            };

            const updateResponse = await axios.post('https://folcon.gentlemenz.in/admin/update-seller', updatePayload, config);

            if (updateResponse.data.status === "success") {
                toast.success('Seller Updated successfully!');
                fetchData();
                setSellerFormData({
                    seller_name: '',
                    address: '',
                    email: '',
                    phone_no: '',
                    is_active: 0,
                });
            } else {
                toast.error('Seller update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating seller:', error);
            toast.error('Seller update failed. Please try again.');
        }
        closeModal();
    };

    const header = (
        // <div className="table-header">
        //     <div>
        //         <h3>Sellers</h3>
        //         <div>
        //             <span className="p-input-icon-left">
        //                 <i className="pi pi-search" />
        //                 <InputText
        //                     type="search"
        //                     value={globalFilter}
        //                     onInput={(e) => setGlobalFilter(e.target.value)}
        //                     placeholder="Search..."
        //                 />
        //             </span>
        //         </div>
        //     </div>
        //     <div className="create-category-button">
        //         <button onClick={() => navigate('/admin/seller-create')} className="btn btn-primary">Create Sellers</button>
        //     </div>
        // </div>
        <></>
    );

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setSellerFormData({
            ...sellerformData,
            is_active: activeValue
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let transformedValue = value;
        if (name == 'seller_name') {
            transformedValue = value.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        }
        if (name == 'address') {
            transformedValue = value.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        }
        if (name == 'email') {
            transformedValue = value.toLowerCase().replace(/[^a-z0-9@._-]/g, '');

        }
        if (name == 'phone_no') {
            transformedValue = value.replace(/[^0-9]/g, '');

        }
        setSellerFormData({ ...sellerformData, [name]: transformedValue });
    };

    const columns = [
        { field: 'seller_id', header: 'ID' },
        { field: 'name', header: 'Seller Name' },
        { field: 'email', header: 'Email' },
        { field: 'phone_no', header: 'phone_no' },
        { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.seller_id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>All Seller</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Seller</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>
            </div>
            <br />
            <div className="pagetitle">
                <div>
                    <button onClick={() => navigate('/admin/product-create')} className="btn btn-primary">
                        <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
                        Create Products
                    </button>
                </div>
                <div className="search-bar">
                    <span className="p-input-icon-left">
                        <input
                            type="search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                            className="custom-input"
                        />
                    </span>
                </div>
            </div>
            <section className="section">
                <DataTable
                    value={currentSeller}
                    globalFilter={globalFilter}
                    emptyMessage="No Seller records found"
                    className="react-data-table">
                    {columns.map((col, i) => (
                        <Column key={i} field={col.field} header={col.header} body={col.body} />
                    ))}
                </DataTable>
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                    nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Seller Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >
                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Seller</h2>
                        </div>
                        <div className="col-auto">
                            <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="seller_name" className="form-label">Name</label>
                            <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                id="seller_name"
                                name="seller_name"
                                value={sellerformData.seller_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="address" className="form-label">Address</label>
                            <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                id="address"
                                name="address"
                                value={sellerformData.address}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                placeholder="Type here"
                                className="form-control"
                                id="email"
                                name="email"
                                value={sellerformData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone_no" className="form-label">Phone No</label>
                            <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                id="phone_no"
                                name="phone_no"
                                value={sellerformData.phone_no}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="toggle" className="form-label">Status</label>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="toggle"
                                    name="is_active"
                                    checked={sellerformData.is_active === 1}
                                    onChange={handleToggleChange}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </form>
                </div>
            </Modal>
        </main>
    );
};

export default SellerList;
