import React, { useEffect, useState } from "react";
// import UploadImage from '../../../assets/images/upload.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatbotcategorryDropdown } from "../../../service/apiService";

import { useNavigate } from "react-router-dom";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const CreatChatbotEnquiry = () => {
  const [PagesformData, setPagesFormData] = useState({

    name: '',
  });

  var navigate = useNavigate();
  const [faqFields, setfaqFields] = useState([{ id: null, dropdownValue: "" }]);
  const [faqList, setfaqList] = useState([]);
  useEffect(() => {
    CategoryDropDownList();
  }, []);

  const token = localStorage.getItem("admin_token");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setPagesFormData({
      ...PagesformData,
      [id]: value,

    });
  };

  const CategoryDropDownList = async () => {
    try {
      const response = await ChatbotcategorryDropdown(token);
      setfaqList(response.data);
    } catch (error) {
      console.error("Error fetching faq list:", error);
    }
  };


  const [validationErrors, setValidationErrors] = useState({

    name: "",
  });


  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...faqFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].id = parseInt(value, 10); // Convert to integer
    setfaqFields(updatedFields);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (faqFields[0].dropdownValue !== "") {
      const token = localStorage.getItem('admin_token');
      //alert(token);
      const csrfToken = localStorage.getItem('csrfToken');
      const errors = {};
      if (!PagesformData.name) {
        errors.name = "Name is required";
      }

      setValidationErrors(errors);
      const faqcategory_id = faqFields[0]?.id || null;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      try {
        if (!PagesformData.name) {
          toast.error('Please Fill The Form Data');
          return;
        }
        const createPayload = {
          ...PagesformData,
          category_id: faqcategory_id,
          name: PagesformData.name,
        };

        const createResponse = await axios.post('https://folcon.gentlemenz.in/admin/create-chatbot-enquiry', createPayload, config);

        if (createResponse.data.status === "success") {

          toast.success('Chatbot Enquiry created successfully!');
          navigate('/admin/chatbot/enquirylist');
          setPagesFormData({
            name: '',

          });
        } else {
          toast.error('Chatbot Enquiry failed. Please try again.');
        }
      } catch (error) {
        console.error('Error creating Chatbot Enquiry:', error);
        toast.error('Chatbot Enquiry creation failed. Please try again.');
      }
    }

    else {
      toast.error("Please select category or enter value");
    }

  };


  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>Chatbot Enquiry Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Chatbot</li>
            <li className="breadcrumb-item active">Enquiry</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Category</label>

                    <div className="col-sm-4">
                      {faqFields.map((category, index) => (



                        <select
                          id={`faq-dropdown-${index}`}
                          className="form-select"
                          value={category.dropdownValue}
                          onChange={(e) => handleChangeDropdowns(index, e.target.value)}
                        >
                          {!faqList ? (
                            <option value="">No Data...</option>
                          ) : faqList.length === 0 ? (
                            <option value="">No category found</option>
                          ) : (
                            <>
                              <option value="">Select Category</option>
                              {faqList.map((categoryItem) => (
                                <option key={categoryItem.id} value={categoryItem.id}>
                                  {categoryItem.name.toUpperCase()}
                                </option>
                              ))}
                            </>
                          )}
                        </select>


                      ))}
                    </div>
                  </div>


                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-4">
                      <input id="name" type="text" value={PagesformData.name} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}

                    </div>
                  </div>


                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </section>
      <AdminFooter />
    </main>

  )



}


export default CreatChatbotEnquiry