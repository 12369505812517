import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UploadImage from '../../../assets/images/defimg.jpeg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import { useNavigate } from "react-router-dom";

const Createcoupon = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const AppUrl = "https://folcon.gentlemenz.in/api/files/";
    const [imageUrl, setImageUrl] = useState([]);
    const token = localStorage.getItem("admin_token");
    const [couponImage, setCouponImage] = useState(null);
    const navigate = useNavigate();

    const [CouponFormData, setCouponFormData] = useState({
        name: '',
        coupon_code: '',
        description: '',
        expire_date: '',
        percentage: '',
        max_time_use: '',
        min_purchase_amount: '',
        is_active: 1
    });

    const [errors, setErrors] = useState({});

    const handleDeletePreview = () => {
        setPreviewImage(null);
        setSelectedImages([]);
    };

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleImageClick = (index) => {
        if (selectedImages.includes(index)) {
            if (selectedImages.length > 0) {
                setSelectedImages(selectedImages.filter(i => i !== index));
            }

        } else {
            setSelectedImages([index]);
            setPreviewImage(AppUrl + imageUrl[index]);
            setCouponImage(imageUrl[index]);
            closeModal();
        }
    };

    useEffect(() => {
        if (token) {
            const response = MediaList(token);
            // if(response.length > 0){
            response.then((response) => {
                const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
                setImageUrl(imageUrls);
            });
            // }
        }
    }, [token]);

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setCouponFormData({
            ...CouponFormData,
            is_active: activeValue
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        // Validate form data
        if (!validateForm()) {
            toast.error('Please fill all required fields');
            return;
        }

        try {
            const percentage = parseFloat(CouponFormData.percentage);
            const min_purchase_amount = parseInt(CouponFormData.min_purchase_amount);
            const max_time_use = parseInt(CouponFormData.max_time_use);

            let imageUrl = null;
            if (selectedFile) {
                const formDataToSend = new FormData();
                formDataToSend.append('file_name', selectedFile);

                const uploadResponse = await axios.post('https://folcon.gentlemenz.in/admin/media-upload', formDataToSend, config);

                if (uploadResponse.data && uploadResponse.data.data) {
                    imageUrl = uploadResponse.data.data;
                } else {
                    toast.error('Error uploading image. Please try again.');
                    return;
                }
            }

            const createPayload = {
                ...CouponFormData,
                image_path: imageUrl ? 'files/' + imageUrl : '',
                name: CouponFormData.name,
                percentage: percentage,
                max_time_use: max_time_use,
                min_purchase_amount: min_purchase_amount
            };

            const createResponse = await axios.post('https://folcon.gentlemenz.in/admin/create-coupon', createPayload, config);

            if (createResponse.data.status === "success") {
                setPreviewImage(null);
                setSelectedFile(null);
                toast.success('Coupon created successfully!');
                setCouponFormData({
                    name: '',
                    coupon_code: '',
                    description: '',
                    expire_date: '',
                    percentage: '',
                    max_time_use: '',
                    min_purchase_amount: '',
                    is_active: 1
                });
                navigate('/admin/coupon-list');
            } else {
                toast.error('Coupon creation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error creating Coupon:', error);
            toast.error('Coupon creation failed. Please try again.');
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!CouponFormData.name) newErrors.name = 'Name is required';
        if (!CouponFormData.coupon_code) newErrors.coupon_code = 'Coupon code is required';
        if (!CouponFormData.description) newErrors.description = 'Description is required';
        if (!CouponFormData.expire_date) newErrors.expire_date = 'Expire date is required';
        if (!CouponFormData.percentage) newErrors.percentage = 'Percentage is required';
        if (!CouponFormData.max_time_use) newErrors.max_time_use = 'Max time use is required';
        if (!CouponFormData.min_purchase_amount) newErrors.min_purchase_amount = 'Min purchase amount is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { id, value } = e.target;

        // Function to generate slug (example implementation, replace with your actual function)
        const generateSlug = (text) => {
            return text.toLowerCase().replace(/\s+/g, '-');
        };

        // Function to filter and allow only numbers and one decimal for percentage and max time use
        const filterToNumbersAndDecimal = (text) => {
            return text.replace(/[^0-9.]/g, '');
        };

        setCouponFormData((prevData) => ({
            ...prevData,
            [id]: id === 'coupon_code' ? value.toUpperCase() : id === 'percentage' || id === 'max_time_use' ? filterToNumbersAndDecimal(value) : value,
            slug: id === 'name' ? generateSlug(value) : prevData.slug
        }));
    };



    const today = new Date().toISOString().split('T')[0];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Coupon Create</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Coupon</li>
                        <li className="breadcrumb-item active">Create</li>
                    </ol>
                </nav>
            </div>
            <section className="section">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <form className="row g-3" onSubmit={handleSubmit}>
                                    <div className="col-md-6">
                                        <label htmlFor="coupon_code" className="col-sm-2 col-form-label">Coupon code</label>
                                        <div className="col-sm-8">
                                            <input id="coupon_code" type="text" value={CouponFormData.coupon_code} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            <small className="form-text text-danger">
                                                <b>Note:</b>  Please enter a coupon code using uppercase letters only.
                                            </small>
                                            {errors.coupon_code && <div className="text-danger">{errors.coupon_code}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                                        <div className="col-sm-8">
                                            <input id="name" type="text" value={CouponFormData.name} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="description" className="col-sm-2 col-form-label">Description</label>
                                        <div className="col-sm-8">
                                            <input id="description" type="text" value={CouponFormData.description} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            {errors.description && <div className="text-danger">{errors.description}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="percentage" className="col-sm-2 col-form-label">Percentage</label>
                                        <div className="col-sm-8">
                                            <input id="percentage" type="text" value={CouponFormData.percentage} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            {errors.percentage && <div className="text-danger">{errors.percentage}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="expire_date" className="col-sm-2 col-form-label">Expire On</label>
                                        <div className="col-sm-8">
                                            <input id="expire_date" type="date" value={CouponFormData.expire_date} placeholder="Type here" onChange={handleChange} className="form-control" min={today} />
                                            {errors.expire_date && <div className="text-danger">{errors.expire_date}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="max_time_use" className="col-sm-2 col-form-label">Max Time Use</label>
                                        <div className="col-sm-8">
                                            <input id="max_time_use" type="text" value={CouponFormData.max_time_use} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            {errors.max_time_use && <div className="text-danger">{errors.max_time_use}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="min_purchase_amount" className="col-sm-2 col-form-label">Min Purchase Amount</label>
                                        <div className="col-sm-8">
                                            <input id="min_purchase_amount" type="text" value={CouponFormData.min_purchase_amount} placeholder="Type here" onChange={handleChange} className="form-control" />
                                            {errors.min_purchase_amount && <div className="text-danger">{errors.min_purchase_amount}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                                        <div className="col-sm-8">
                                            <input className="form-check-input" type="checkbox" id="toggle" checked={CouponFormData.is_active === 1} onChange={handleToggleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Preview</label>
                                        <br />
                                        {previewImage ? (
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                                                <button
                                                    type="button"
                                                    onClick={handleDeletePreview}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        right: '5px',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                        border: 'none',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </div>
                                        ) : (
                                            <img src={UploadImage} alt="Alt" />
                                        )}
                                        <br />
                                        <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openModal}>
                                            Select Image
                                        </button>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <SingleImageSelect
                        isOpen={modalIsOpen}
                        handleClose={closeModal}
                        handleImageClick={handleImageClick}
                        selectedImages={selectedImages}
                        imageUrl={imageUrl}
                        token={token}
                        setImageUrl={setImageUrl}
                    />
                </div>
            </section>
        </main>
    );
};

export default Createcoupon;
