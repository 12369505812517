import React, { useRef, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import fetchCSS from "../../../Helper/fetchCss";

const CssPage = () => {
  const textareaRef = useRef(null);
  const [cssContent, setCssContent] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const fetchAdminCss = async () => {
      try {
        const token = localStorage.getItem("admin_token");
        const csrfToken = localStorage.getItem("csrfToken");

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
        };
        const response = await axios.get(
          "https://folcon.gentlemenz.in/admin/get-admin-css",
          config
        );
        const { data } = response.data;
        setCssContent(data.css_content);
        autoResizeTextarea(); // Call auto-resize after fetching content
      } catch (error) {
        console.error("Error fetching admin CSS:", error);
        toast.error("Failed to fetch admin CSS");
      }
    };

    fetchAdminCss();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state while waiting for response
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await axios.post(
        "https://folcon.gentlemenz.in/admin/update-admin-css",
        {
          id: 1,
          css_content: cssContent,
        },
        config
      );


      const res = await axios.post(
        "http://localhost:3001/update-css",
        {
          cssContent,
        }
      );
      const updatedCssContent = response.data.data.css_content;
      fetchCSS(updatedCssContent);
      setCssContent(updatedCssContent);

      toast.success(response.data.message);
    } catch (error) {
      console.error("Error updating admin CSS:", error);
      toast.error("Failed to update admin CSS");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleTextareaChange = (e) => {
    setCssContent(e.target.value);
    autoResizeTextarea(); // Call auto-resize on textarea change
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Custom Css</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Css</li>
            <li className="breadcrumb-item active">Add</li>
          </ol>
        </nav>
      </div>
      <div className="col-sm-12" id="user-verification-container">
        <div className="card">
          <div className="card-body row">
            <div className="col-lg-12 col-12 ps-0">
              <div className="card card-info shadow-none mb-0">
                <div className="alert alert-warning fade in alert-dismissable">
                  <strong>Warning!</strong>
                  <a
                    className="close"
                    href="/"
                    aria-label="close"
                    title="close"
                  >
                    ×
                  </a>
                </div>
                <div id="smtp_head">
                  <div className="card-header p-t-20 border-bottom">
                    <h5>CSS</h5>
                  </div>
                </div>
                <div className="card-body p-l-15">
                  <div>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="create-css-button">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "Save"}{" "}
                          {/* Show loading text if loading */}
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="primary_input mb-25">
                            <textarea
                              className="primary_textarea autoresizing"
                              placeholder="Write custom CSS here..."
                              name="custom_css"
                              ref={textareaRef}
                              style={{
                                // resize: "none",
                                width: "100%",
                                height: "auto", // Initial height
                              }}
                              value={cssContent}
                              onChange={handleTextareaChange}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
};

export default CssPage;
