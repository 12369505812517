import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Getorderdetails, Getorderdetailsupdate } from "../../../service/apiService";


const Orderlist = () => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const navigate = useNavigate();

    const cellStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    const headerStyle = {
        backgroundColor: '#f2f2f2',
        textAlign: 'left',
    };

    const token = localStorage.getItem('admin_token');
    const [Orderlists, setOrderlist] = useState([]);
    const [orderStatus, setOrderStatus] = useState('');
    const [paymentStatus, setpaymentStatus] = useState('');

    useEffect(() => {
        fetchData();

    }, []);

    const fetchData = async () => {
        try {
            const OrderId = localStorage.getItem('OrderId');
            //   alert(OrderId);
            const response = await Getorderdetails(token, OrderId);
            console.log(response);
            if (response.data) {
                setOrderlist(response.data);
                setOrderStatus(response.data.order_status); // Set initial order status
                setpaymentStatus(response.data.payment_status);

            }
        }
        catch (error) {
            console.log(error);
        }
    };

    if (!Orderlists) {
        return <div>Loading...</div>;
    }

    const {
        order_date,
        order_code,
        customer_name,
        customer_email,
        total_products,
        total_count,
        total_amount,
        billing_address = {},
        shipping_address = {},
        purchase_list = [],

    } = Orderlists;


    const handleStatusChange = (event) => {
        const newOrderStatus = event.target.value;
        const userConfirmed = window.confirm("Are you sure you want to change the Order status?");

        if (userConfirmed) {
            setOrderStatus(newOrderStatus);
        }

    };

    const handlePaymentStatusChange = (event) => {
        const newPaymentStatus = event.target.value;
        const usersConfirmed = window.confirm("Are you sure you want to change the Payment status?");
        if (usersConfirmed) {
            setpaymentStatus(newPaymentStatus);
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            const OrderId = parseInt(localStorage.getItem('OrderId'));
            const createPayload = {
                id: OrderId,
                order_code: order_code,
                order_status: orderStatus,
                payment_status: paymentStatus
            };
            // const createResponse = await Getorderdetailsupdate(token, createPayload, config);
            const createResponse = await axios.post('https://folcon.gentlemenz.in/admin/update-order', createPayload, config);


            if (createResponse.status === 200) {
                toast.success('Order updated successfully!');
                fetchData();
                navigate(`/admin/all-orders`);
            } else {
                toast.error('Order update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating order:', error);
            toast.error('Order update failed. Please try again.');
        }
    };






    return (

        <>
            <main id="main" className="main">
                <AdminHeader /><SideBar />

                <div className='container' >
                    <div className="row">
                        <div className="col">
                            <h3><b>Order Details</b></h3>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <p><b>Order_date:</b> {order_date}</p>


                            </div>
                            <div className="col-6">
                                <p><b>Order Code:</b> <b>{order_code}</b></p>


                            </div>

                        </div>


                        <div className="row">
                            <div className="col-6">
                                <p><b>Customer name:</b> {customer_name}</p>


                            </div>
                            <div className="col-6">
                                <p><b>Customer email:</b> {customer_email}</p>


                            </div>

                        </div>

                        <div className="row">
                            <div className="col-6">
                                <p><b>Total products:</b> {total_products}</p>


                            </div>
                            <div className="col-6">
                                <p><b>Total count:</b> {total_count}</p>


                            </div>

                        </div>

                        <div className="row">
                            <div className="col-6">
                                <p><b>Total amount:</b> <b>{total_amount}</b></p>


                            </div>
                            <div className="col-6">
                                <p>
                                    <b>Billing address:</b>
                                    {billing_address && (
                                        <>


                                            {billing_address.billing_name}, <br />
                                            {billing_address.billing_email}, <br />
                                            {billing_address.billing_phone_no}, <br />
                                            {billing_address.billing_address_line_one}, <br />
                                            {billing_address.billing_address_line_two}, <br />
                                            {billing_address.billing_city},<br />
                                            {billing_address.billing_state}, {billing_address.billing_country},  {billing_address.billing_pincode}

                                        </>
                                    )}
                                </p>


                            </div>

                        </div>

                        <div className="row">
                            <div className="col-6">
                                <p>
                                    <b> Shipping address: </b>
                                    {shipping_address && (
                                        <>

                                            {shipping_address.shipping_name}, <br />
                                            {shipping_address.shipping_email}, <br />
                                            {shipping_address.shipping_phone_no}, <br />

                                            {shipping_address.shipping_address_line_one}, <br />
                                            {shipping_address.address_line_two}, <br />
                                            {shipping_address.shipping_city},<br />
                                            {shipping_address.shipping_state}, {shipping_address.shipping_country},  {shipping_address.shipping_pincode}

                                        </>
                                    )}
                                </p>

                            </div>


                        </div>


                        <h5 className="mt-5"><b>Purchase list</b></h5>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={{ ...cellStyle, ...headerStyle }}>S.No</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}> product_name</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>variant_name</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>variant_value</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>price</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>discount_price</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>quantity</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>tax_name</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>tax_percentage</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>tax_amount</th>

                                    <th style={{ ...cellStyle, ...headerStyle }}>sales_amount</th>
                                    <th style={{ ...cellStyle, ...headerStyle }}>sub_total_amount</th>



                                </tr>
                            </thead>

                            <tbody>

                                {purchase_list.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.product_name}</td>
                                        <td style={cellStyle}>{item.variant_name}</td>
                                        <td style={cellStyle}>{item.variant_value}</td>
                                        <td style={cellStyle}>{item.price}</td>
                                        <td style={cellStyle}>{item.discount_price}</td>
                                        <td style={cellStyle}>{item.quantity}</td>
                                        <td style={cellStyle}>{item.tax_name}</td>
                                        <td style={cellStyle}>{item.tax_percentage}</td>
                                        <td style={cellStyle}>{item.tax_amount}</td>
                                        <td style={cellStyle}>{item.sales_amount}</td>
                                        <td style={cellStyle}>{item.sub_total_amount}</td>
                                    </tr>
                                ))}

                            </tbody>


                        </table>
                        <form onSubmit={handleSubmit}>

                            <div className="row">



                                {Orderlists.payment_status === 'unpaid' && Orderlists.order_status == 'pending' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status : {Orderlists.order_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="payment_status"
                                                name="payment_status"
                                                value={paymentStatus}
                                                onChange={handlePaymentStatusChange}
                                            >
                                                <option value={Orderlists.payment_status} selected>{Orderlists.payment_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="paid">Paid</option>


                                            </select>

                                        </div>


                                    </>

                                )}


                                {Orderlists.payment_status === 'paid' && Orderlists.order_status === "returned" && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status : {Orderlists.order_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="payment_status"
                                                name="payment_status"
                                                value={paymentStatus}
                                                onChange={handlePaymentStatusChange}
                                            >
                                                <option value={Orderlists.payment_status} selected>{Orderlists.payment_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="refund">Refund</option>


                                            </select>

                                        </div>


                                    </>

                                )}



                                {Orderlists.payment_status === 'paid' && Orderlists.order_status == 'pending' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="processing">Processing</option>


                                            </select>

                                        </div>


                                    </>

                                )}


                                {Orderlists.order_status === 'processing' && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="shipping">Shipping</option>


                                            </select>

                                        </div>


                                    </>

                                )}



                                {Orderlists.order_status === 'shipping' && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="delivered">Delivered</option>


                                            </select>

                                        </div>


                                    </>

                                )}




                                {Orderlists.order_status === 'return_request' && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="returned">Returned</option>


                                            </select>

                                        </div>


                                    </>

                                )}


                                {Orderlists.order_status === 'delivered' && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="return_request">Return request</option>


                                            </select>

                                        </div>


                                    </>

                                )}



                                {Orderlists.order_status === 'cancelled' && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status : {Orderlists.order_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="payment_status"
                                                name="payment_status"
                                                value={paymentStatus}
                                                onChange={handlePaymentStatusChange}
                                            >
                                                <option value={Orderlists.payment_status} selected>{Orderlists.payment_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="refund">Refund</option>


                                            </select>

                                        </div>

                                    </>

                                )}


                                {(Orderlists.order_status === 'processing' || Orderlists.order_status === 'shipping' || Orderlists.order_status === 'pending') && Orderlists.payment_status === 'paid' && (
                                    <>

                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Payment status : {Orderlists.payment_status}</b>
                                            </label>
                                            {/* Other elements inside this div */}
                                        </div>


                                        <div className="mt-4 col-4">
                                            <label htmlFor="label" className="form-label">
                                                <b> Order status </b>
                                            </label>


                                            <select
                                                className="form-select"
                                                id="order_status"
                                                name="order_status"
                                                value={orderStatus}
                                                onChange={handleStatusChange}
                                            >
                                                <option value={Orderlists.order_status} selected>{Orderlists.order_status}</option>
                                                <option value="" disabled>Select an option</option>
                                                <option value="cancelled">Cancelled</option>


                                            </select>

                                        </div>


                                    </>

                                )}


                                {!(
                                    Orderlists.payment_status === 'unpaid' && Orderlists.order_status !== 'pending' ||

                                    Orderlists.payment_status === 'paid' && Orderlists.order_status === 'returned' ||
                                    Orderlists.payment_status === 'paid' && Orderlists.order_status !== 'pending' ||
                                    Orderlists.payment_status === 'paid' && Orderlists.order_status === 'processing' ||
                                    Orderlists.payment_status === 'paid' && Orderlists.order_status === 'shipping' ||
                                    Orderlists.payment_status === 'paid' && Orderlists.order_status === 'return_request' ||
                                    Orderlists.payment_status === 'paid' && Orderlists.order_status === 'cancelled' ||
                                    Orderlists.order_status === 'delivered' && Orderlists.payment_status === 'paid'
                                ) && (
                                        <>
                                            <div className="mt-4 col-4">
                                                <label htmlFor="label" className="form-label">
                                                    <b> Order status : {Orderlists.order_status}</b>
                                                </label>
                                                {/* Other elements inside this div */}
                                            </div>
                                            <div className="mt-4 col-4">
                                                <label htmlFor="label" className="form-label">
                                                    <b> payment status : {Orderlists.payment_status}</b>
                                                </label>
                                                {/* Other elements inside this div */}
                                            </div>
                                        </>
                                    )}


                                {/* <div className="mt-4 col-4">
                                <label htmlFor="label" className="form-label">
                               <b> Order status</b>
                                </label>
                                <select
                                className="form-select"
                                id="order_status"
                                name="order_status"
                                value={orderStatus}
                                onChange={handleStatusChange}
                            >
                            <option value="" disabled>Select an option</option>
                             <option value="pending">Pending</option>
                               
                                <option value="processing">Processing</option>
                               <option value="shipping">Shipping</option>
                                <option value="delivered">Delivered</option>
                              
                                <option value="cancelled">Cancelled</option>
                                <option value="return_request">Return Request</option>
                             
                                <option value="returned">Returned</option>
                            </select>
                            </div>

                            <div className="mt-4 col-4">
                                <label htmlFor="label" className="form-label">
                               <b> Payment status </b>
                                </label>
                               

                                <select
                                className="form-select"
                                id="order_status"
                                name="order_status"
                                value={paymentStatus}
                                onChange={handlePaymentStatusChange}
                            >
                                <option value="" disabled>Select an option</option>
                                <option value="paid">Paid</option>
                                <option value="unpaid">Unpaid</option>

                                <option value="refund">Refund</option>
                               
                            </select>
                              
                            </div>  */}
                            </div>


                            <div className="row">

                                <button type="submit" className="btn btn-primary mt-5 col-1">Update</button>
                            </div>

                        </form>

                    </div>

                </div>

            </main>
            <AdminFooter />

        </>
    );


}

export default Orderlist;