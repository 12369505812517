import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "../../../../assets/images/defimg.jpeg";
import SingleImageSelect from "../../../../components/Media/SingleImageSelect";
import { MediaList, createBlogAPICall, getAllBlogCategoriesAPICall, getAllBlogTagsAPICall, updateBlogAPICall } from "../../../../service/apiService";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AdminFooter from "../../Dashboard/AdminFooter";
import AdminHeader from "../../Dashboard/AdminHeader";
import SideBar from "../../Dashboard/SideBar";

const BlogEdit = ({ editFormData, setEditBlogForm, getAllBlogs }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState('');
    const [blogCategories, setblogCategories] = useState([]);
    const [blogTagsCategories, setblogTagsCategories] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const token = localStorage.getItem("admin_token");
    const AppUrl = "https://folcon.gentlemenz.in/api/files/";
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const [description, setDescription] = useState('');
    const [errFlag, setErrFlag] = useState(false);
    const [blogFormData, setBlogFormData] = useState(editFormData);

    useEffect(() => {
        if (token) {
            const response = MediaList(token);
            response.then((response) => {
                const imageUrls = response.data.filter(
                    (url) =>
                        url.endsWith(".jpg") ||
                        url.endsWith(".png") ||
                        url.endsWith(".jpeg") ||
                        url.endsWith(".gif")
                );
                setImageUrl(imageUrls);
            });
        }
    }, [token]);

    useEffect(() => {
        setDescription(editFormData.message);
        setSelectedImages(editFormData.image_path);
        setPreviewImage(AppUrl + editFormData.image_path)
        getAllBlogCategories();
        getAllBlogTags();
    }, [])


    const getAllBlogCategories = async () => {
        try {
            const response = await getAllBlogCategoriesAPICall(token);
            if (response.status_code === 200) {
                setblogCategories(response.data);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getAllBlogTags = async () => {
        try {
            const response = await getAllBlogTagsAPICall(token);
            if (response.status_code === 200) {
                console.log("getAllBlogCategoriesAPICall", response.data);
                setblogTagsCategories(response.data);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlogFormData({
            ...blogFormData,
            [name]: value,
        });
    };

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setBlogFormData({
            ...blogFormData,
            is_active: activeValue
        });
    };

    const handleImageClick = (index) => {
        // if (selectedImages.includes(index)) {
        //     if (selectedImages.length > 0) {
        //         setSelectedImages(selectedImages.filter((i) => i !== index));
        //     }
        // } else {
        setSelectedImages(imageUrl[index]);
        setPreviewImage(AppUrl + imageUrl[index]);
        closeModal();
        // }
    };

    const handleDeletePreview = () => {
        setPreviewImage(null);
        setSelectedImages('');
    };


    const formValidation = () => {
        console.log('selectedImages', selectedImages);
        if (!blogFormData.title.trim() || !blogFormData.blog_category_id || !blogFormData.blog_tag_id || selectedImages === ""
            || !blogFormData.description.trim() || description === "") {
            setErrFlag(true)
            return false;
        }
        return true
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isFormValid = formValidation();
        if (!isFormValid) {
            return;
        }
        try {
            const token = localStorage.getItem('admin_token');
            let payload = {
                "id": blogFormData.id,
                "blog_category_id": Number(blogFormData.blog_category_id),
                "blog_tag_id": Number(blogFormData.blog_tag_id),
                "title": blogFormData.title,
                "description": blogFormData.description,
                "image_path": selectedImages,
                "message": description,
                "is_active": blogFormData.is_active
            }
            const response = await updateBlogAPICall(token, payload)
            if (response.status_code === 200) {
                toast.success('Blog created successfully !');
                setErrFlag(false);
                setEditBlogForm(false);
                getAllBlogs()
            }
        } catch (error) {
            console.error('Error creating Blog :', error);
            toast.error('Blog creation failed. Please try again.');
        }
    };

    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main className="main-wrap">
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Blog Edit</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/dashboard">Dashbord</a>
                                </li>
                                <li className="breadcrumb-item">Blogs</li>
                                <li className="breadcrumb-item active">Blog</li>
                                <li className="breadcrumb-item active">Edit</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="row g-3">
                                            <div className="col-md-6">
                                                <label htmlFor="title" className="form-label">
                                                    Blog title
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="enter the title"
                                                    className="form-control"
                                                    id="title"
                                                    name="title"
                                                    value={blogFormData.title}
                                                    onChange={handleChange}
                                                />
                                                {errFlag && blogFormData.title == '' ? <div className="text-danger" >Please enter title</div> : null}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="blog_category_id" className="form-label">
                                                    Blog category
                                                </label>
                                                <select
                                                    id="blog_category_id"
                                                    className="form-select"
                                                    name="blog_category_id"
                                                    value={blogFormData.blog_category_id}
                                                    onChange={handleChange}
                                                >
                                                    {!blogCategories ? (
                                                        <option value="">No Data...</option>
                                                    ) : blogCategories.length === 0 ? (
                                                        <option value="">No blog category found</option>
                                                    ) : (
                                                        <>
                                                            <option value="">Select blog category</option>
                                                            {blogCategories.map((blogCategory) => (
                                                                <option key={blogCategory.id} value={blogCategory.id}>
                                                                    {blogCategory.name.toUpperCase()}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                                {errFlag && blogFormData.blog_category_id == '' ? <div className="text-danger" >Please select blog category</div> : null}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="inputState" className="form-label">
                                                    Blog tag
                                                </label>
                                                <select
                                                    id="blog_tag_id"
                                                    className="form-select"
                                                    value={blogFormData.blog_tag_id}
                                                    onChange={handleChange}
                                                    name="blog_tag_id"
                                                >
                                                    {!blogTagsCategories ? (
                                                        <option value="">No Data...</option>
                                                    ) : blogTagsCategories.length === 0 ? (
                                                        <option value="">No blog tag found</option>
                                                    ) : (
                                                        <>
                                                            <option value="">Select blog tag</option>
                                                            {blogTagsCategories.map((blogCategory) => (
                                                                <option key={blogCategory.id} value={blogCategory.id}>
                                                                    {blogCategory.name.toUpperCase()}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                                {errFlag && blogFormData.blog_tag_id == '' ? <div className="text-danger" >Please select blog tag</div> : null}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="description" className="form-label">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter the description"
                                                    className="form-control"
                                                    id="description"
                                                    name="description"
                                                    value={blogFormData.description}
                                                    onChange={handleChange}
                                                />
                                                {errFlag && blogFormData.description == '' ? <div className="text-danger" >Please enter description</div> : null}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="is_active" className="col-sm-2 col-form-label">Status</label>
                                                <div className="col-sm-4">
                                                    <input className="form-check-input" type="checkbox" id="toggle" checked={blogFormData.is_active == 1 ? true : false} onChange={handleToggleChange} />
                                                    <label htmlFor="is_active" className="form-check-label"> {true ? "Active" : "Inactive"}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="inputPassword5" className="form-label">
                                                    Message
                                                </label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setDescription(data);
                                                    }}
                                                    config={{
                                                        toolbar: [
                                                            'heading', '|',
                                                            'bold', 'italic', 'blockQuote', '|',
                                                            'numberedList', 'bulletedList', '|',
                                                            'undo', 'redo'
                                                        ],
                                                    }}
                                                />
                                            </div>
                                            {errFlag && description == '' ? <div className="text-danger" >Please enter message</div> : null}
                                            <div className="col-md-6">
                                                <label>Thumbnail Image</label>
                                                <br />
                                                {previewImage ? (
                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                        <img
                                                            src={previewImage}
                                                            alt="Preview"
                                                            style={{
                                                                maxWidth: "100%",
                                                                width: "200px",
                                                                height: "200px",
                                                            }}
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={handleDeletePreview}
                                                            style={{
                                                                position: "absolute",
                                                                top: "5px",
                                                                right: "5px",
                                                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                                                border: "none",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <img src={UploadImage} alt="Alt" />
                                                )}
                                                <br></br>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mt-2 btn-sm btn-block"
                                                        onClick={openModal}
                                                    >
                                                        Select Image
                                                    </button>
                                                </div>
                                                {errFlag && selectedImages == '' ? <div className="text-danger" >Please select images</div> : null}
                                            </div>
                                            <hr></hr>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <SingleImageSelect
                                    isOpen={modalIsOpen}
                                    handleClose={closeModal}
                                    handleImageClick={handleImageClick}
                                    selectedImages={selectedImages}
                                    imageUrl={imageUrl}
                                    token={token}
                                    setImageUrl={setImageUrl}
                                />
                            </div>
                        </div>
                    </section>
                </main>
            </main>
            <AdminFooter />
        </div>
    );
};

export default BlogEdit;
