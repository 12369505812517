import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Modal from 'react-modal';
import UploadImage from '../../../assets/images/upload.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import SingleImageSelect from '../../../components/Media/SingleImageSelect';
import { MediaList } from "../../../service/apiService";

import { useNavigate } from 'react-router-dom';
import noImage from "../../../assets/images/noimage.jpg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";





const DiscountList = () => {
  const navigate = useNavigate();

  const [Discountlist, setDiscountlist] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [DiscountFormData, setDiscountFormData] = useState({
    name: '',
    slug: '',
    percentage: '',
    from_date: '',
    to_date: ''
  });
  const [selectedDiscountId, setSelectedDiscountId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [imag, setImag] = useState(null);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;

  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [discountImage, setDiscountImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const AppUrl = "https://folcon.gentlemenz.in/api/files/";
  const [deletedImage, setDeletedImage] = useState(false);
  const handleDeletePreview = () => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setPreviewImage(null);
      setSelectedImages([]);
      setDiscountImage(null);
      setDeletedImage(true);
    }
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setDiscountImage(imageUrl[index]);
      closeMediaModal();
    }
  };




  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
    }
  }, [token]);



  const fetchData = async () => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await fetch('https://folcon.gentlemenz.in/admin/all-discounts', config);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();

      let DiscountData = [];

      if (Array.isArray(responseData)) {
        DiscountData = responseData;
      } else if (Array.isArray(responseData.data)) {
        DiscountData = responseData.data;
      } else {
        throw new Error('Data is not in the expected format');
      }
      setDiscountlist(DiscountData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);


  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);

  const openMediaModal = () => {
    setMediaModalIsOpen(true);
  };

  const closeMediaModal = () => {
    setMediaModalIsOpen(false);
  };


  const handleEdit = async (DiscountId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await axios.get(`https://folcon.gentlemenz.in/admin/get-discount/${DiscountId}`, config);
      const responseData = response.data;
      setDiscountFormData({
        name: responseData.data.name,
        slug: responseData.data.slug,
        percentage: responseData.data.percentage,
        from_date: responseData.data.from_date,
        to_date: responseData.data.to_date,
        status: responseData.data.status,
        is_active: responseData.data.is_active,
        image_path: responseData.data.image_path


      });
      setSelectedDiscountId(DiscountId);
      const previewImageUrl = responseData.data.image_path ? appBaseUrl.concat(responseData.data.image_path) : null;
      setPreviewImage(previewImageUrl);
      setImag(responseData.data.image_path);
      openEditModal();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const header = (
    <div className="table-header">

      {/* <div>
        <h3>Discount List</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button onClick={() => navigate('/admin/discount-create')} className="btn btn-primary">Create Discount</button>
      </div> */}
    </div>
  );

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "from_date" && name === "to_date") {
      // For date fields, simply update the state with the value
      setDiscountFormData({
        ...DiscountFormData,
        [name]: value,
      });
    } else if (name === "name") {
      // If it's the name field, also update the slug
      const uppercaseValue = value.toUpperCase(); // Convert value to uppercase
      const slug = uppercaseValue.toLowerCase().replace(/\s+/g, '-');
      setDiscountFormData({
        ...DiscountFormData,
        [name]: uppercaseValue,
        slug: slug,
      });
    } else if (name === "percentage") {
      // If it's the percentage field, ensure it's a numeric value
      if (/^\d*\.?\d*$/.test(value)) { // Check if value contains only digits and an optional decimal point
        setDiscountFormData({
          ...DiscountFormData,
          [name]: value,
        });
      }
    } else {
      // For other fields, simply update the state
      setDiscountFormData({
        ...DiscountFormData,
        [name]: value,
      });
    }
  };










  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setDiscountFormData({
      ...DiscountFormData,
      is_active: activeValue
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!DiscountFormData.name || !DiscountFormData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }




      const createPayload = {
        ...DiscountFormData,
        name: DiscountFormData.name,
        percentage: parseFloat(DiscountFormData.percentage),
        // slug:DiscountFormData.slug,
        // tax_id : TaxFormData.id,
        id: selectedDiscountId, // Include the TaxId here
        image_path: 'files/' + discountImage,


      };

      const newImageUrl = DiscountFormData.image_path ? DiscountFormData.image_path : 'files/' + discountImage;
      createPayload.image_path = newImageUrl;
      if (deletedImage === true) {
        createPayload.image_path = null;
      }

      const createResponse = await axios.post('https://folcon.gentlemenz.in/admin/update-discount', createPayload, config);

      if (createResponse.data.status === "success") {
        toast.success('Discount Updated successfully!');
        fetchData();
        setDiscountFormData({
          name: '',
          slug: '',
          percentage: '',
          from_date: '',
          to_date: '',
          status: '',
          is_active: 0,
        });
      } else {
        toast.error('Discount creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Discount:', error);
      toast.error('Discount creation failed. Please try again.');
    }
    closeEditModal();
  };




  const pageCount = Math.ceil(Discountlist.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentDiscountlist = Discountlist.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)


  const columns = [
    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Discount Name' },
    { field: 'percentage', header: 'Discount' },
    {
      field: 'image_path',
      header: 'Image',
      body: (rowData) => {
        if (rowData.image_path) {
          return <img src={appBaseUrl + rowData.image_path} width={100} height={100} alt="discount" />;
        } else {
          return <img src={noImage} width={100} height={100} alt="No Image" />;
        }
      }
    }, { field: 'from_date', header: 'Start Date', body: (rowData) => formatDate(rowData.from_date) },
    { field: 'to_date', header: 'End Date', body: (rowData) => formatDate(rowData.to_date) },
    { field: 'slug', header: 'Slug' },
    { field: 'status', header: 'Status' },
    { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
    {
      field: 'action', header: 'Action', body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={() => handleEdit(rowData.id)}>Edit info</a>
              {/* <a className="dropdown-item text-danger" onClick={() => handleDelete(rowData)}>Delete</a> */}
            </div>
          </div>
        </div>
      )
    }
  ];
  const filteredProducts = Discountlist?.filter((product) =>
    product.product_name?.toLowerCase().includes(globalFilter.toLowerCase())
  );


  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);


  return (
    <main id="main" className="main">

      <div className="pagetitle">
        <h1>Discount List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
          </ol>
        </nav>
      </div>
      <br />
      <div className="pagetitle">
        <div>
          <button onClick={() => navigate('/admin/discount-create')} className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
            Create Discount
          </button>
        </div>
        <div className="search-bar">
          <span className="p-input-icon-left">
            <input
              type="search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="custom-input"
            />
          </span>
        </div>
      </div>
      <section className="section">

        <DataTable
          data={currentDiscountlist}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          className="react-data-table"
          emptyMessage="No Discount records found"
        >
          {columns.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} body={col.body} />
          ))}
        </DataTable>
        <div className="pagetitle">
          <div className="results-info">
            Showing {startItem} - {endItem} of {filteredProducts?.length} results
          </div>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>


      </section>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Discount Info"
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'relative',
            backgroundColor: '#fff',
            borderRadius: '10px',
            padding: '20px',
            // maxWidth: '80%',
            maxHeight: '100%',
            overflowY: 'auto',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          },
        }}
      >


        <div className='container' style={{ width: '900px' }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Discount</h2>
            </div>
            <div className="col-auto"></div>
            <button onClick={closeEditModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>



              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control"
                  id="name"
                  name="name"
                  value={DiscountFormData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="slug" className="form-label">
                  Slug
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control"
                  id="slug"
                  name="slug"
                  value={DiscountFormData.slug}
                  onChange={handleChange}
                  readOnly={true}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="percentage" className="form-label">
                  Percentage

                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control "
                  id="percentage"
                  name="percentage"
                  value={DiscountFormData.percentage}
                  onChange={handleChange}

                />
              </div>
              <div className="col-md-6">
                <label htmlFor="from_date" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  placeholder="Type here"
                  className="form-control"
                  id="from_date"
                  name="from_date"
                  value={DiscountFormData.from_date ? DiscountFormData.from_date.split('T')[0] : ''}
                  onChange={handleChange}

                />
              </div>
              <div className="col-md-6">
                <label htmlFor="to_date" className="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  placeholder="Type here"
                  className="form-control"
                  id="to_date"
                  name="to_date"
                  value={DiscountFormData.to_date ? DiscountFormData.to_date.split('T')[0] : ''}
                  onChange={handleChange}

                />
              </div>
              <div className="col-md-6">
                <label htmlFor="status" className="form-label">
                  Discount Status
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control"
                  id="status"
                  name="status"
                  value={DiscountFormData.status}
                  onChange={handleChange}
                  readOnly={true}
                />
              </div>



              <div className="col-md-6">
                <label htmlFor="toggle" className="form-label">
                  Status
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggle"
                    name="is_active"
                    checked={DiscountFormData.is_active === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>
              <div className="mb-4">

                <div className="card-header">
                  <h4>Preview</h4>
                  <br />
                  {previewImage ? (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />

                      <button
                        type="button"
                        onClick={handleDeletePreview}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  ) : (
                    <img src={UploadImage} alt="Alt" />
                  )}
                  <br></br>
                  <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openMediaModal}>
                    Select Image
                  </button>



                </div>
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary">Update</button>

              </div>
            </form>


          </div>

        </div>

        <SingleImageSelect
          isOpen={mediaModalIsOpen}
          handleClose={closeMediaModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
          token={token}
          setImageUrl={setImageUrl}
        />
      </Modal>
    </main>
  );
};

export default DiscountList;