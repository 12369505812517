import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faTrashAlt, faArrowLeft, faArrowRight, faEllipsisVertical, faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import UploadImage from '../../../assets/images/upload.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ReactDataTable from '../../../components/DataTable';
import ReactPaginate from 'react-paginate';
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import noImage from "../../../assets/images/noimage.jpg";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Button } from "react-bootstrap";

const BrandList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [brands, setBrands] = useState([]);
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const ImageAppUrl = 'https://folcon.gentlemenz.in/api/files/';
  const ListUrl = 'https://folcon.gentlemenz.in/api/';
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    is_active: 0,
    brand_id: ''
  });

  const [brandImage, setBrandImage] = useState(null); // Initialize state for brandImage
  const [imag, setImag] = useState(null);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const AppUrl = "https://folcon.gentlemenz.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [deletedImage, setDeletedImage] = useState(false);
  const [imageMediamodalIsOpen, setImageMediamodalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 7;

  const filteredProducts = brands?.filter((brand) =>
    brand.brand_name?.toLowerCase().includes(globalFilter.toLowerCase())
  );

  const pageCount = Math.ceil(brands.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentBrands = brands.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage).map((brand, index) => ({
    ...brand,
    sno: pageNumber * itemsPerPage + index + 1,
  }));

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter(i => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(ImageAppUrl + imageUrl[index]);
      setBrandImage(imageUrl[index]); // Use setBrandImage to update state
      closeImageModal();
    }
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setFormData({
      ...formData,
      is_active: activeValue
    });
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };

  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data?.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
    }
  }, [token]);

  const deleteImage = () => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      setSelectedFile(null);
      setPreviewImage(null);
      setShowTrashIcon(false);
      setImag(null);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await fetch('https://folcon.gentlemenz.in/admin/all-brands', config);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();

      let brandsData = [];

      if (Array.isArray(responseData)) {
        brandsData = responseData;
      } else if (Array.isArray(responseData.data)) {
        brandsData = responseData.data;
      } else {
        throw new Error('Data is not in the expected format');
      }

      setBrands(brandsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setShowTrashIcon(true);
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = async (brandId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await axios.get(`https://folcon.gentlemenz.in/admin/get-brand/${brandId}`, config);
      const responseData = response.data;
      setFormData({
        name: responseData.data.brand_name,
        slug: responseData.data.slug,
        brand_id: responseData.data.id,
        is_active: responseData.data.is_active,
        brand_image: responseData.data.brand_image
      });
      setSelectedBrandId(brandId);
      const previewImageUrl = responseData.data.brand_image ? ImageAppUrl.concat(responseData.data.brand_image) : null;
      setPreviewImage(previewImageUrl);
      setImag(responseData.data.brand_image);
      setBrandImage(responseData.data.brand_image); // Set brandImage here
      openEditModal();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeletePreview = () => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      setPreviewImage(null);
      setSelectedImages([]);
      setBrandImage(null); // Set brandImage to null
      setDeletedImage(true);
      formData.brand_image = null;
    }
  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const openImageModal = () => {
    setImageMediamodalIsOpen(true);
  };

  const closeImageModal = () => {
    setImageMediamodalIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!formData.name || !formData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const createPayload = {
        ...formData,
        brand_image: brandImage, // Use brandImage from state
        brand_name: formData.name,
        brand_id: formData.brand_id,
      };
      const newImageUrl = brandImage ? brandImage : formData.brand_image;
      createPayload.brand_image = newImageUrl;
      if (deletedImage === true) {
        createPayload.brand_image = null;
      }

      const createResponse = await axios.post('https://folcon.gentlemenz.in/admin/update-brand', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success('Brand Updated Successfully');
        setFormData({
          name: '',
          slug: '',
          is_active: 0,
          brand_id: ''
        });
        closeEditModal();
        fetchData();
      } else {
        toast.error('Something Went Wrong');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Something Went Wrong');
    }
  };

  const handleDelete = async (brandId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      if (window.confirm('Are you sure you want to delete this brand?')) {
        const response = await axios.delete(`https://folcon.gentlemenz.in/admin/delete-brand/${brandId}`, config);
        if (response.status === 200) {
          setBrands(brands?.filter((brand) => brand.id !== brandId));
          toast.success('Brand deleted successfully!');
        } else {
          toast.error('Failed to delete the brand. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
      toast.error('Failed to delete the brand. Please try again.');
    }
  };

  const columns = [
    { field: 'sno', header: 'S.No', sortable: true, width: '10%' },
    { field: 'brand_name', header: 'Brand Name', sortable: true, width: '20%' },
    { field: 'slug', header: 'Slug', sortable: true, width: '20%' },
    {
      field: 'is_active', header: 'Is Active', body: (rowData) => {
        return <input type="checkbox" checked={rowData.is_active} disabled />;
      }, sortable: true, width: '10%'
    },
    {
      field: 'brand_image', header: 'Image', body: (rowData) => {
        return rowData.brand_image ? (
          <img src={AppUrl.concat(rowData.brand_image)} alt={rowData.brand_name} width={70} className="small-image" />
        ) : (
          <img src={noImage} alt={rowData.brand_name} width={70} className="small-image" />
        );
      }, sortable: true, width: '10%'
    },
    {
      field: 'actions', header: 'Actions', body: (rowData) => (
        <div className="actions">
          <span onClick={() => handleEdit(rowData.id)}><FontAwesomeIcon icon={faPenToSquare} className="brand-view-icon" /></span>
        </div>
      ), width: '30%'
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    if (name === 'name') {
      updatedFormData.slug = generateSlug(value);
    }

    setFormData(updatedFormData);
  };


  const startItem = pageNumber * itemsPerPage + 1;
  const endItem = Math.min((pageNumber + 1) * itemsPerPage, filteredProducts.length);


  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Brand List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Brand</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <br />
      <div className="pagetitle">
        <div>
          <button onClick={() => navigate('/admin/brands/create')} className="btn btn-primary">
            <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;
            Create Brands
          </button>
        </div>
        <div className="search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <input
              type="search"
              placeholder="Search..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="custom-input"
            />
          </span>
        </div>
      </div>
      <div>
        <ReactDataTable
          columns={columns}
          data={currentBrands}
          totalRecords={brands.length}
          onPageChange={handlePageChange}
          rowsPerPage={itemsPerPage}
          emptyMessage="No Category records found"
          globalFilter={globalFilter}
        />
      </div>
      <div className="pagination-container">
        <div className="results-info">
          Showing {startItem} - {endItem} of {filteredProducts?.length} results
        </div>
        <ReactPaginate
          pageCount={pageCount}
          breakLabel={"..."}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
          nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
        />
      </div>


      <ToastContainer />

      {/* Edit Brand Modal */}
      <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal} className="brand-edit-modal">
        <div className="modal-header">
          <h5>Edit Brand</h5>
          <button onClick={closeEditModal} className="close-button"><FontAwesomeIcon icon={faCircleXmark} /></button>
        </div>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="form-group">
              <TextField
                id="name"
                name="name"
                label="Brand Name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <TextField
                id="slug"
                name="slug"
                label="Slug"
                variant="outlined"
                fullWidth
                value={formData.slug}
                onChange={handleInputChange}
                disabled
              />
            </div>
            <div className="form-group">
              <FormControlLabel
                control={
                  <Checkbox
                    id="is_active"
                    name="is_active"
                    checked={formData.is_active}
                    onChange={handleToggleChange}
                  />
                }
                label="Is Active"
              />
            </div>

            <div className="col-md-6">
              <label>Preview</label>
              <br />
              {previewImage ? (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <img
                    src={previewImage}
                    style={{ maxWidth: "100%", width: "200px", height: "200px" }}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeletePreview}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      boxShadow: 'none'
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </div>
              ) : (
                <img src={UploadImage} alt="Alt" />
              )}
              <br />
              <Button
                variant="contained"
                color="primary"
                className="mt-2"
                onClick={openImageModal}
              >
                Select Image
              </Button>
            </div>
          </div>
          <div className="modal-footer">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button type="button" variant="outlined" color="secondary" onClick={closeEditModal}>
              Cancel
            </Button>
          </div>
        </form>

      </Modal>
      {/* Image Selection Modal */}
      <SingleImageSelect
        isOpen={imageMediamodalIsOpen}
        handleImageClick={handleImageClick}
        selectedImages={selectedImages}
        imageUrl={imageUrl}
        token={token}
        setImageUrl={setImageUrl}
        handleClose={closeImageModal}
      />
    </main>
  );
};

export default BrandList;
